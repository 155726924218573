export class AUTHAPIs {
  public static get AUTHORIZATIONAPILIST(): any {
    return [
      '/api/profile',
      '/edit-profile',
      '/cancel-appointment',
      '/add-appointment-review',
      '/api/get-favourites',
      '/api/change-password',
      '/delete-favourites',
      '/api/business-bookings',
      '/api/get-business-details',
      '/api/update-business-service-status',
      '/api/remove-business-service',
      '/api/enable-disable-online-service',
      '/api/update-business-practitioner-status',
      '/api/update-scheduling-appointment',
      '/api/remove-business-practitioner',
      '/api/get-business-calendar-practitioner',
      '/api/update-business-calendar',
      '/api/sync-external-calendar-business',
      '/api/update-calendar-info',
      '/api/update-business-details',
      '/wellbeing/all-employees',
      '/wellbeing/intern-employees',
      '/wellbeing/employees',
      '/wellbeing/director-employees',
      '/wellbeing/deactivated-employees',
      '/wellbeing/all-report-and-performance',
      '/wellbeing/interns-report-and-performance',
      '/wellbeing/employees-report-and-performance',
      '/wellbeing/directors-report-and-performance',
      '/wellbeing/deactivated-report-and-performance',
      '/wellbeing/add-employee',
      '/wellbeing/edit-employee',
      '/wellbeing/employee-detail',
      '/wellbeing/journey-detail',
      '/wellbeing/journeys',
      '/wellbeing/unassigned-employee-list',
      '/wellbeing/bulk-assigned-journey',
      '/wellbeing/personal-detail',
      '/wellbeing/get-booking-details',
      '/wellbeing/cancel-booking',
      '/wellbeing/reschedule-booking',
      '/wellbeing/edit-journey',
      '/wellbeing/journey-list',
      '/wellbeing/remaining-journeys',
      '/wellbeing/import-employee-format',
      '/wellbeing/import-employee',
      '/wellbeing/delete-employee',
      '/wellbeing/undelete-employee',
      '/wellbeing/send-invitation-all',
      '/wellbeing/send-employee-invitation',
      '/update-business-settings',
      '/update-service-suburb-radius',
      '/update-service-software-link',
      '/remove-service-image',
      '/remove-class-image',
      '/create-update-booking-class',
      '/remove-practitioner-image',
      '/change-appointment-status',
      '/create-list',
      '/get-lists',
      '/add-to-list',
      '/create-update-business-logo',
      '/update-notification-preferences',
      '/create-update-business-practitioner',
      '/add-business-calendar',
      '/get-business-calendar',
      '/enable-disable-pay-at-venue',
      'remove-business-image',
      '/logout/',
      '/wellbeing/signup/',
      '/wellbeing/all-employees/',
      '/wellbeing/intern-employees/',
      '/wellbeing/employees/',
      '/wellbeing/director-employees/',
      '/wellbeing/deactivated-employees/',
      '/wellbeing/all-report-and-performance/',
      '/wellbeing/interns-report-and-performance/',
      '/wellbeing/employees-report-and-performance/',
      '/wellbeing/directors-report-and-performance/',
      '/wellbeing/deactivated-report-and-performance/',
      '/wellbeing/add-employee/',
      '/wellbeing/edit-employee/',
      '/wellbeing/employee-detail/',
      '/wellbeing/journey-detail/',
      '/wellbeing/journeys/',
      '/wellbeing/unassigned-employee-list/',
      '/wellbeing/bulk-assigned-journey/',
      '/wellbeing/personal-detail/',
      '/wellbeing/get-booking-details/',
      '/wellbeing/cancel-booking/',
      '/wellbeing/reschedule-booking/',
      '/wellbeing/edit-journey/',
      '/wellbeing/journey-list/',
      '/wellbeing/remaining-journeys/',
      '/wellbeing/journey-detail/',
      '/wellbeing/import-employee-format/',
      '/wellbeing/import-employee/',
      '/wellbeing/delete-employee/',
      '/wellbeing/undelete-employee/',
      '/wellbeing/send-invitation-all/',
      '/wellbeing/send-employee-invitation/',
      '/wellbeing/create-wallet-topup-intent/',
      '/wellbeing/wallet-topup/',
      '/wellbeing/get-billing-detail/',
      '/get-list-detail',
      '/get-business-appointments',
      '/get-business-classes',
      '/get-business-practitioners',
      '/get-business-appointment-detail',
      '/get-business-class-detail',
      '/get-about-business-details',
      '/get-scheduling-appointment',
      '/create-update-business-appointments',
      '/remove-business-class',
      '/remove-business-logo',
      'bookings',
      '/send-post-appointment-form-pdf',
      'appointment-detail',
      'change-appointment-status',
      'ahrr-form-list',
      'case-note-form-list',
      'monthly-report-form-list',
      'send-case-note-form-pdf',
      '/add-business-image',
      'reschedule-appointment',
      'customer-bookings',
      'appointment-reschedule',
      'get-practitioner-override-schedule',
      'all-patients-list',
      'partners-list',
      'partner-services-list',
      'update-appointment-details',
      'update-business-practitioner',
      'update-practitioner-holidays-list',
      'update-practitioner-override-schedule',
      'update-business-service',
      'update-business-booking-class',
      'get-practitioner-holidays-list',
      'validate-business-abn',
      'abn-lookup-business-name',
      'fetch-specializations',
      'fetch-professions',
      'fetch-qualifications',
      'fetch-insurances',
      'fetch-categories',
      'delete-business-calendar',
      'get-business-appointment-detail',
      'update-business-settings',
      'remove-business-service',
      'remove-service-image',
      'delete-list',
      'update-list',
      'practitioners-listing',
      'search-service',
      'remove-from-list',
      'customer-booking-detail',
      'validate-token',
      'get-notifications',
      'workcover/validate-temporary-appointment-request',
      'workcover/update-temporary-appointment-details',
      'workcover/get-appointment-practitioner-invoice',
      'workcover/create-appointment',
      '/business-block-times',
      '/sync-specific-business',
      '/fetch-amenities',
      '/business-dashboard-insights',
      '/client-list',
      '/read-all-notifications',
      'unread-all-notifications',
      '/read-notification',
      '/seen-notification',
      '/seen-all-notifications',
      '/generate-about-business',
      '/generate-practitioner-bio',
      '/add-registered-business-location',
      '/create-business-location',
      '/business-profile-percentage',
      '/appointment-dashboard',
      '/payments/',
      '/export-payments',
      '/validate-sira',
      '/validate-ahpra',
      '/get-user-detail',
      '/get-dashboard-business-detail',
      '/create-subsequent-booking-link',
      '/fetch-subsequent-services',
      'fetch-invited-practitioner',
      'get-uninvited-practitioner',
      'send-invitation-to-practitioners',
      'resend-invitation-to-practitioners',
      'create-practitioner-password',
      'save-subsequent-booking-status',
      'send-case-note-upload-pdf',
      'submit-other-invoices',
      'check-subsequent-booking'
    ];
  }
}
