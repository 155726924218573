import { environment } from 'src/environments/environment';

export class Constants {
  public static get BASE_URL(): string {
    return 'https://prodapi.avaana.com.au/api';
  }

  public static get WORKCOVER(): string {
    return 'https://prodapi.avaana.com.au/workcover';
  }

  public static get WELLBEING_URL(): string {
    return 'https://prodapi.avaana.com.au/wellbeing';
  }

  public static get WORKCOVER_URL(): string {
    return 'https://api-logichealth.avaana.com.au/workcover';
  }

  public static get EML_WORKCOVER_URL(): string {
    return 'https://api-eml.avaana.com.au/';
  }

  public static get TRINITY_WORKCOVER_URL(): string {
    return 'https://api-trinity.avaana.com.au/';
  }

  public static get HII_WORKCOVER_URL(): string {
    return 'https://api-hii.avaana.com.au/';
  }

  public static get BASE_URL_withoutAPI(): string {
    return 'https://prodapi.avaana.com.au/';
  }

  public static get BLOG_URL(): string {
    return 'https://avaana.com.au/blog/wp-json/custom-api';
  }

  public static get BASE_URL_1(): string {
    return 'https://avaana.com.au/';
  }

  public static get BLOG_CONTENT_API_URL(): string {
    return 'https://avaana.com.au/blog/wp-json/wp/v2/pages?slug=';
  }

  public static get BLOG_PAGES_API_URL(): string {
    return 'https://avaana.com.au/blog/wp-json/wp/v2/pages';
  }

  public static get BLOG_POST_API_URL(): string {
    return 'https://avaana.com.au/blog/wp-json/wp/v2/posts';
  }

  public static get BLOG_USER_API_URL(): string {
    return 'https://avaana.com.au/blog/wp-json/wp/v2/users';
  }

  public static get BLOG_TAGS_API_URL(): string {
    return 'https://avaana.com.au/blog/wp-json/wp/v2/tags';
  }

  public static get BLOG_CATEGORIES_API_URL(): string {
    return 'https://avaana.com.au/blog/wp-json/wp/v2/categories';
  }

  public static get BLOG_POPULAR_POSTS(): string {
    return 'https://avaana.com.au/blog/wp-json/custom-api/posts-ordered';
  }

  public static get TEST_STRIPE_KEY(): any {
    return 'pk_test_ruRdTof7lfDG6PlD1WFntsmZ';
  }

  public static get PROD_STRIPE_KEY(): any {
    return 'pk_live_nGCzNyBi6ZScbVpXcr8d0SwH';
  }

  public static get STRIPE_KEY(): any {
    return Constants.PROD_STRIPE_KEY;
  }

  public static get AWS_SECRET_NAME(): any {
    return environment.aws.secretName;
  }

  public static get AWS_ACCESS_KEY(): any {
    return environment.aws.accessKey;
  }

  public static get AWS_SECRET_KEY(): any {
    return environment.aws.secretAccessKey;
  }

  public static get BOOKING_CALENDAR_SYSTEM(): any {
    const bookingCalendarSystem = [
      '10to8',
      'Acuity',
      'Bookeo',
      'ClassPass',
      'Charm EHR',
      'Cliniko',
      'Coreplus',
      'Fresha',
      'Frontdesk',
      'Full Slate',
      'JaneApp',
      'Google',
      'Halaxy',
      'iCal',
      'Keap',
      'Mindbody',
      'Nookal',
      'Paper Diary',
      'Powerdiary',
      'Setmore',
      'Simplybook.me',
      'Smartsoft',
      'Timely',
      'Wix',
      'Other'
    ];

    return bookingCalendarSystem;
  }

  public static get BOOKING_CALENDAR_SYSTEM_API_TYPE(): any {
    const bookingCalendarSystemAPIType = ['Acuity', 'Cliniko', 'Coreplus', 'Mindbody', 'Nookal'];

    return bookingCalendarSystemAPIType;
  }

  public static get TIMINGLIST(): string[] {
    return [
      '12:00 AM',
      '12:15 AM',
      '12:30 AM',
      '12:45 AM',
      '01:00 AM',
      '01:15 AM',
      '01:30 AM',
      '01:45 AM',
      '02:00 AM',
      '02:15 AM',
      '02:30 AM',
      '02:45 AM',
      '03:00 AM',
      '03:15 AM',
      '03:30 AM',
      '03:45 AM',
      '04:00 AM',
      '04:15 AM',
      '04:30 AM',
      '04:45 AM',
      '05:00 AM',
      '05:15 AM',
      '05:30 AM',
      '05:45 AM',
      '06:00 AM',
      '06:15 AM',
      '06:30 AM',
      '06:45 AM',
      '07:00 AM',
      '07:15 AM',
      '07:30 AM',
      '07:45 AM',
      '08:00 AM',
      '08:15 AM',
      '08:30 AM',
      '08:45 AM',
      '09:00 AM',
      '09:15 AM',
      '09:30 AM',
      '09:45 AM',
      '10:00 AM',
      '10:15 AM',
      '10:30 AM',
      '10:45 AM',
      '11:00 AM',
      '11:15 AM',
      '11:30 AM',
      '11:45 AM',
      '12:00 PM',
      '12:15 PM',
      '12:30 PM',
      '12:45 PM',
      '01:00 PM',
      '01:15 PM',
      '01:30 PM',
      '01:45 PM',
      '02:00 PM',
      '02:15 PM',
      '02:30 PM',
      '02:45 PM',
      '03:00 PM',
      '03:15 PM',
      '03:30 PM',
      '03:45 PM',
      '04:00 PM',
      '04:15 PM',
      '04:30 PM',
      '04:45 PM',
      '05:00 PM',
      '05:15 PM',
      '05:30 PM',
      '05:45 PM',
      '06:00 PM',
      '06:15 PM',
      '06:30 PM',
      '06:45 PM',
      '07:00 PM',
      '07:15 PM',
      '07:30 PM',
      '07:45 PM',
      '08:00 PM',
      '08:15 PM',
      '08:30 PM',
      '08:45 PM',
      '09:00 PM',
      '09:15 PM',
      '09:30 PM',
      '09:45 PM',
      '10:00 PM',
      '10:15 PM',
      '10:30 PM',
      '10:45 PM',
      '11:00 PM',
      '11:15 PM',
      '11:30 PM',
      '11:45 PM'
    ];
  }

  public static get WorkingTIMINGLIST(): string[] {
    return [
      '08:00 AM',
      '08:15 AM',
      '08:30 AM',
      '08:45 AM',
      '09:00 AM',
      '09:15 AM',
      '09:30 AM',
      '09:45 AM',
      '10:00 AM',
      '10:15 AM',
      '10:30 AM',
      '10:45 AM',
      '11:00 AM',
      '11:15 AM',
      '11:30 AM',
      '11:45 AM',
      '12:00 PM',
      '12:15 PM',
      '12:30 PM',
      '12:45 PM',
      '01:00 PM',
      '01:15 PM',
      '01:30 PM',
      '01:45 PM',
      '02:00 PM',
      '02:15 PM',
      '02:30 PM',
      '02:45 PM',
      '03:00 PM',
      '03:15 PM',
      '03:30 PM',
      '03:45 PM',
      '04:00 PM',
      '04:15 PM',
      '04:30 PM',
      '04:45 PM',
      '05:00 PM',
      '05:15 PM',
      '05:30 PM',
      '05:45 PM',
      '06:00 PM',
      '06:15 PM',
      '06:30 PM',
      '06:45 PM',
      '07:00 PM',
      '07:15 PM',
      '07:30 PM',
      '07:45 PM',
      '08:00 PM'
    ];
  }

  public static get SUBCATEGORY_LIST(): any {
    const subCatList = {
      acupuncture: [
        {
          key: 'moxibustion',
          name: 'Moxibustion',
          slug: '/acupuncture/moxibustion',
          img: 'assets/images/category/thai-massage.svg',
          title: 'Techniques used in Acupuncture',
          keywords: 'moxibustion, moxa stick, moxibustion sticks, moxibustion acupuncture',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1713417610/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'guasha',
          name: 'Guasha',
          slug: '/acupuncture/guasha',
          img: 'assets/images/category/guasha-1.jpg',
          title: '',
          keywords: 'gua sha, guasha, gua sha tool, gua sha stone, gua sha facial, gua sha massage',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1713417610/gdp0b7e6vmqcpen0altq.webp'
        }
      ],
      ayurveda: [
        {
          key: 'shirodhara',
          name: 'Shirodhara',
          slug: '/ayurveda/shirodhara',
          img: 'assets/images/category/shirodhara-1.jpg',
          title: 'Different Ayurvedic systems',
          keywords:
            'shirodhara, shirodhara near me, shirodhara treatment, shirodhara treatment near me, shirodhara therapy',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583955483/m0ithsd3gb9ux6tundqt.webp'
        },
        {
          key: 'abhyanga',
          name: 'Abhyanga',
          slug: '/ayurveda/abhyanga',
          img: 'assets/images/category/shirodhara-2.jpg',
          title: '',
          keywords: '',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583955483/m0ithsd3gb9ux6tundqt.webp'
        }
      ],
      counselling: [
        {
          key: 'cognitive-behavioral-therapy',
          name: 'Cognitive Behavioral Therapy',
          slug: '/counselling/cognitive-behavioral-therapy',
          img: 'assets/images/category/thai-massage.webp',
          title: 'Types of Counselling',
          keywords:
            'cognitive behavioural therapy, cbt therapy, behavioral therapy, cbt professional, cbt therapy near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583955954/hu4yjx7t8tnwmjpctne6.webp'
        },
        {
          key: 'couples-counselling',
          name: 'Couples Counselling',
          slug: '/counselling/couples-counselling',
          img: 'assets/images/category/couples-counselling-1.png',
          title: '',
          keywords:
            'marriage counseling, couples counselling, relationship counselling, marriage counseling near me, couples counseling near me, relationship counseling near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583955954/hu4yjx7t8tnwmjpctne6.webp'
        },
        {
          key: 'general-counselling',
          name: 'General Counselling',
          slug: '/counselling/general-counselling',
          img: 'assets/images/category/general-counselling=1.png',
          title: '',
          keywords: 'general counselling, counselling, general psychologists near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583955954/hu4yjx7t8tnwmjpctne6.webp'
        },
        {
          key: 'neuro-linguistic-programming',
          name: 'Neuro Linguistic Programming',
          slug: '/counselling/neuro-linguistic-programming',
          img: 'assets/images/category/deep-tissue-massage.webp',
          title: '',
          keywords: 'neuro linguistic programming, neuro linguistic, neuro linguistic practitioner',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583955954/hu4yjx7t8tnwmjpctne6.webp'
        }
      ],
      'group-fitness': [
        {
          key: 'hiit-group-training',
          name: 'Hiit Group Training',
          slug: '/group-fitness/hiit-group-training',
          img: 'assets/images/category/hiit-training-1.png',
          title: 'Types of group fitness activities',
          keywords:
            'boxercise classes near me, hiit exercises, high intensity interval training, HIIT near me, metafit',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954327/vwlbxc12wslr4l2wc5fl.webp'
        },
        {
          key: 'boxing',
          name: 'Boxing',
          slug: '/group-fitness/boxing',
          img: 'assets/images/category/boxing-1.jpg',
          title: 'The more you sweat, the less you bleed ',
          keywords:
            'boxing gyms near me, boxing gyms, boxing classes near me, kicking boxing near me, boxing class, boxing clubs near me, boxing fitness, boxing training near me, kickbox near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954327/vwlbxc12wslr4l2wc5fl.webp'
        },
        {
          key: 'jiu-jitsu',
          name: 'Jiu-Jitsu',
          slug: '/group-fitness/jiu-jitsu',
          img: 'assets/images/category/jiu-jitsu-1.png',
          title: '',
          keywords:
            'jiu jitsu near me, bjj near me, brazilian jiu jitsu near me, jiu jitsu schools, bjj gym, bjj gyms near me, ',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954327/vwlbxc12wslr4l2wc5fl.webp'
        },
        {
          key: 'karate',
          name: 'Karate',
          slug: '/group-fitness/karate',
          img: 'assets/images/category/karate-1.jpg',
          title: '',
          keywords:
            'martial artist near me, karate near me, karate classes near me, karate class, karate lessons near me, karate dojos',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954327/vwlbxc12wslr4l2wc5fl.webp'
        },
        {
          key: 'kickboxing',
          name: 'Kickboxing',
          slug: '/group-fitness/kickboxing',
          img: 'assets/images/category/kickboxing-1.jpg',
          title: '',
          keywords:
            ' kickboxing near me, kickboxing classes near me, best kickboxing class near me, kickboxing training near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954327/vwlbxc12wslr4l2wc5fl.webp'
        },
        {
          key: 'dance',
          name: 'Dance',
          slug: '/group-fitness/dance',
          img: 'assets/images/category/dance.jpeg',
          title: '',
          keywords: 'dance class near me,adult dance classes near me, best dance class near me, dance lessons near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954327/vwlbxc12wslr4l2wc5fl.webp'
        }
      ],
      massage: [
        {
          key: 'aromatherapy-massage',
          name: 'Aromatherapy Massage',
          slug: '/massage/aromatherapy-massage',
          img: 'assets/images/category/aromatherapy-1.webp',
          title: 'Find out which Massage is best for you?',
          keywords: 'aromatherapy massage, aroma massage, aromatherapy massage near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'ayurvedic-massage',
          name: 'Ayurvedic Massage',
          slug: '/massage/ayurvedic-massage',
          img: 'assets/images/category/ayurvedic-massage-1.jpg',
          title: '',
          keywords:
            'ayurvedic massage, ayurvedic massage near me, shirodhara massage, ayurvedic spa, shirodhara near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'chinese-massage',
          name: 'Chinese Massage',
          slug: '/massage/chinese-massage',
          img: 'assets/images/category/swedish-massage.webp',
          title: '',
          keywords: 'chinese massage, chinese traditional massage, chinese reflexology foot, chinese massage nearby',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'deep-tissue-massage',
          name: 'Deep Tissue Massage',
          slug: '/massage/deep-tissue-massage',
          img: 'assets/images/category/deep-tissue-massage.webp',
          title: '',
          keywords: 'deep tissue massage near me, deep tissue, deep muscle massage, deep tissue massage gold coast',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'couples-massage',
          name: 'Couples Massage',
          slug: '/massage/couples-massage',
          img: 'assets/images/category/couples-massage-1.webp',
          title: '',
          keywords: 'couples spa packages, couples spa day, couples massage near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'cupping-therapy',
          name: 'Cupping Therapy',
          slug: '/massage/cupping-therapy',
          img: 'assets/images/category/cupping-therapy-1.webp',
          title: '',
          keywords:
            'cupping therapy, cupping massage, hijama, cupping therapy near me, wet cupping, suction cup therapy',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'foot-massage-reflexology',
          name: 'Foot Massage Reflexology',
          slug: '/massage/foot-massage-reflexology',
          img: 'assets/images/category/foot-massage-1.webp',
          title: '',
          keywords:
            'foot spa, foot reflexology, pressure point on feet, foot and calf massager, reflexology massage, foot massage near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'lymphatic-drainage-massage',
          name: 'Lymphatic Drainage Massage',
          slug: '/massage/lymphatic-drainage-massage',
          img: 'assets/images/category/lymphatic-drainage-massage.webp',
          title: '',
          keywords:
            'lymphatic drainage massage, lymphatic drainage, lymphatic massage, lymphatic drainage massage near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'thai-massage',
          name: 'Thai Massage',
          slug: '/massage/thai-massage',
          img: 'assets/images/category/thai-massage.webp',
          title: '',
          keywords: 'thai massage, thai massage near me, thai herbal, thai massage near me ',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'hot-stone-massage',
          name: 'Hot Stone Massage',
          slug: '/massage/hot-stone-massage',
          img: 'assets/images/category/hot-stone-massage.webp',
          title: '',
          keywords:
            'hot stone massage near me,best hot stone massage near me, hot stone massage places near me, hot stone massage therapy near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'remedial-massage',
          name: 'Remedial Massage',
          slug: '/massage/remedial-massage',
          img: 'assets/images/category/remedial-massage.jpg',
          title: '',
          keywords:
            'remedial massage near me, best remedial massage near me, remedial massage places near me, remedial massage centre near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'sports-massage',
          name: 'Sports Massage',
          slug: '/massage/sports-massage',
          img: 'assets/images/category/sports-massage.jpg',
          title: '',
          keywords:
            'sports massage near me, sports massage therapy near me, best sports massage near me, sports massage places near me, sports massage centre near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'pregnancy-massage',
          name: 'Pregnancy Massage',
          slug: '/massage/pregnancy-massage',
          img: 'assets/images/category/pregnancy-massage.jpg',
          title: '',
          keywords:
            'pregnancy massage near me, sports massage therapy near me, best pregnancy massage near me, pregnancy massage places near me, pregnancy massage centre near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        }
      ],
      myotherapy: [
        {
          key: 'dry-needling',
          name: 'Dry Needling',
          slug: '/myotherapy/dry-needling',
          img: 'assets/images/category/dry-needling-1.jpg',
          title: 'Techniques of Myotherapy',
          keywords: 'dry needling near me, needling, needling therapy',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583953571/ofiv0zivrp8g7nxsz57u.webp'
        }
      ],
      pilates: [
        {
          key: 'clinical-pilates',
          name: 'Clinical-Pilates',
          slug: '/pilates/clinical-pilates',
          img: 'assets/images/category/remedial-massage.jpg',
          title: 'Types of Pilates',
          keywords: 'clinical pilates, clinical pilates near me, physio pilates near me, pilates physio',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954991/usbdn8zt8zbxzbiwqqkn.webp'
        }
      ],
      yoga: [
        {
          key: 'ashtanga-yoga',
          name: 'Ashtanga Yoga',
          slug: '/yoga/ashtanga-yoga',
          img: 'assets/images/category/ashtanga-1.jpg',
          title: 'Types of Yoga',
          keywords: '',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954830/xahgtrtlbgpajgrzwxzw.webp'
        },
        {
          key: 'bikram-yoga',
          name: 'Bikram Yoga',
          slug: '/yoga/bikram-yoga',
          img: 'assets/images/category/bikram-yoga-1.jpg',
          title: '',
          keywords: 'bikram yoga, bikram yoga near me, bikram yoga postures',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954830/xahgtrtlbgpajgrzwxzw.webp'
        },
        {
          key: 'hatha-yoga',
          name: 'Hatha Yoga',
          slug: '/yoga/hatha-yoga',
          img: 'assets/images/category/hatha-yoga-1.jpg',
          title: '',
          keywords: 'hatha yoga sequences, hatha yoga for beginners, hatha yoga sequences, hatha yoga near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954830/xahgtrtlbgpajgrzwxzw.webp'
        },
        {
          key: 'hot-yoga',
          name: 'Hot Yoga',
          slug: '/yoga/hot-yoga',
          img: 'assets/images/category/hatha-yoga-2.jpg',
          title: '',
          keywords: '',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954830/xahgtrtlbgpajgrzwxzw.webp'
        },
        {
          key: 'iyengar-yoga',
          name: 'Iyengar Yoga',
          slug: '/yoga/iyengar-yoga',
          img: 'assets/images/category/iyengar-yoga-2.jpg',
          title: '',
          keywords: 'iyengar yoga postures, iyengar yoga chair, iyengar yoga props, iyengar yoga sequences',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583954830/xahgtrtlbgpajgrzwxzw.webp'
        }
      ],
      reiki: [
        {
          key: 'energy-healing',
          name: 'Energy Healing',
          slug: '/reiki/energy-healing',
          img: 'assets/images/category/energy-healing-1.jpg',
          title: 'Types of Reiki',
          keywords:
            'crystal heal, reiki healer, energy healing,chakra meditations, chakra healing, pranic healer, healer near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583955268/sxsdhtraiugx1ocehog7.webp'
        },
        {
          key: 'crystal-healing',
          name: 'Crystal Healing',
          slug: '/reiki/crystal-healing',
          img: 'assets/images/category/crystal-healing-1.jpg',
          title: 'Types of Reiki',
          keywords:
            'crystal heal, reiki healer, energy healing,chakra meditations, chakra healing, pranic healer, healer near me',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583955268/sxsdhtraiugx1ocehog7.webp'
        }
      ],
      'day-spa': [
        {
          key: 'nail-treatment',
          name: 'Nail Treatment',
          slug: '/day-spa/nail-treatment',
          img: 'assets/images/category/remedial-massage.jpg',
          title: 'Get the best medicure!',
          keywords:
            'whitening nails, toenail fungus, fungal nails, nail fungus treatment, ingrown toenail treatment, toenail fungus treatment',
          cover: ''
        }
      ],
      gym: [
        {
          key: 'gym',
          name: 'Gym ',
          slug: '/gym',
          img: 'assets/images/category/gym.svg',
          title: 'Gym Near Me - Explore & Join Best Gym Now | Avaana',
          keywords: 'gym near me, best gym class near me, gyms nearby, fitness center near me',
          cover: ''
        }
      ],
      'martial-arts': [
        {
          key: 'judo',
          name: 'Judo near me',
          slug: '/martial-arts/judo',
          img: 'assets/images/category/martial-arts.svg',
          title: '',
          keywords: 'judo near me, judo classes near me, best judo class near me, judo schools near me',
          cover: ''
        }
      ],
      'therapeutic-supports': [
        {
          key: 'moxibustion',
          name: 'Moxibustion',
          slug: '/therapeutic-supports',
          img: 'assets/images/category/thai-massage.svg',
          title: 'Techniques used in therapeutic-supports',
          keywords: 'moxibustion, moxa stick, moxibustion sticks, moxibustion acupuncture',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        },
        {
          key: 'guasha',
          name: 'Guasha',
          slug: '/acupuncture/guasha',
          img: 'assets/images/category/guasha-1.jpg',
          title: '',
          keywords: 'gua sha, guasha, gua sha tool, gua sha stone, gua sha facial, gua sha massage',
          cover:
            'https://res.cloudinary.com/avaana/image/upload/w_700,h_400,c_limit/q_auto/f_auto/v1583900634/gdp0b7e6vmqcpen0altq.webp'
        }
      ],
      physiotherapy: [
        {
          key: 'musculoskeletal',
          name: 'Musculoskeletal',
          slug: '/musculoskeletal',
          img: 'assets/images/category/musculoskeletal.svg',
          title: 'Musculoskeletal Health - Find Specialized Care | Avaana',
          keywords: 'musculoskeletal health, orthopedic care, bone and joint health, physical therapy',
          cover: ''
        },
        {
          key: 'neurological',
          name: 'Neurological',
          slug: '/neurological',
          img: 'assets/images/category/neurological.svg',
          title: 'Neurological Care - Explore Services for Neurological Health | Avaana',
          keywords: 'neurological care, neurology services, brain health, neurological disorders',
          cover: ''
        },
        {
          key: 'cardiovascular',
          name: 'Cardiovascular',
          slug: '/cardiovascular',
          img: 'assets/images/category/cardiovascular.svg',
          title: 'Cardiovascular Health - Discover Heart Care Services | Avaana',
          keywords: 'cardiovascular health, heart care, cardiovascular diseases, cardiology services',
          cover: ''
        },
        {
          key: 'pediatric',
          name: 'Pediatric',
          slug: '/pediatric',
          img: 'assets/images/category/pediatric.svg',
          title: 'Pediatric Care - Find Specialized Health Services for Children | Avaana',
          keywords: "pediatric care, children's health, pediatricians, child wellness",
          cover: ''
        },
        {
          key: 'geriatric',
          name: 'Geriatric',
          img: 'assets/images/category/geriatric.svg',
          title: 'Geriatric Care - Explore Health Services for Seniors | Avaana',
          keywords: 'geriatric care, elderly health, senior care, aging well',
          cover: ''
        },
        {
          key: 'womens-health',
          name: "Women's Health",
          slug: '/womens-health',
          img: 'assets/images/category/womens-health.svg',
          title: "Women's Health - Comprehensive Care for Women | Avaana",
          keywords: "women's health, gynecology services, women's wellness, reproductive health",
          cover: ''
        },
        {
          key: 'sports',
          name: 'Sports',
          slug: '/sports',
          img: 'assets/images/category/sports.svg',
          title: 'Sports Medicine - Enhance Performance with Specialized Care | Avaana',
          keywords: 'sports medicine, athletic care, sports injuries, performance enhancement',
          cover: ''
        },
        {
          key: 'orthopedic',
          name: 'Orthopedic',
          slug: '/orthopedic',
          img: 'assets/images/category/orthopedic.svg',
          title: 'Orthopedic Care - Specialized Services for Bone and Joint Health | Avaana',
          keywords: 'orthopedic care, bone and joint health, orthopedic surgery, musculoskeletal health',
          cover: ''
        }
      ]
    };
    return subCatList;
  }

  public static get HEALTH_CONDITIONS(): any {
    const conditions = [
      'diarrhoea',
      'dental-pain',
      'nausea',
      'osteoarthritis',
      'back-pain',
      'anxiety',
      'arthritis',
      'asthma',
      'headaches',
      'neck-pain',
      'menstrual-cramps',
      'vomiting',
      'depression',
      'fatigue',
      'gut-health',
      'high-blood-pressure',
      'hot-flashes',
      'infertility',
      'insomnia',
      'pms',
      'stress',
      'diabetes'
    ];
    return conditions;
  }

  public static get DEFAULT_HOURS(): any {
    return [
      {
        start_timing: '08:00 AM',
        end_timing: '06:00 PM',
        business_day: 'Monday',
        day_status: 'Closed'
      },
      {
        start_timing: '08:00 AM',
        end_timing: '06:00 PM',
        business_day: 'Tuesday',
        day_status: 'Closed'
      },
      {
        start_timing: '08:00 AM',
        end_timing: '06:00 PM',
        business_day: 'Wednesday',
        day_status: 'Closed'
      },
      {
        start_timing: '08:00 AM',
        end_timing: '06:00 PM',
        business_day: 'Thursday',
        day_status: 'Closed'
      },
      {
        start_timing: '08:00 AM',
        end_timing: '06:00 PM',
        business_day: 'Friday',
        day_status: 'Closed'
      },
      {
        start_timing: '08:00 AM',
        end_timing: '06:00 PM',
        business_day: 'Saturday',
        day_status: 'Closed'
      },
      {
        start_timing: '08:00 AM',
        end_timing: '06:00 PM',
        business_day: 'Sunday',
        day_status: 'Closed'
      }
    ];
  }
  public static get EMAIL_VALIDATION(): any {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))|(^.+@[^.].*\.[a-z]{2,})$/;
  }

  public static get RELATED_CATEGORIES(): any {
    const relatedCategories = {
      acupuncture: ['chiropractic', 'massage', 'physiotherapy', 'podiatry', 'reflexology', 'osteopathy'],
      ayurveda: ['naturopathy', 'nutrition', 'reiki', 'homeopathy'],
      chiropractic: ['acupuncture', 'massage', 'physiotherapy', 'reflexology', 'podiatry', 'osteopathy'],
      counselling: ['meditation', 'psychology', 'life-coaching'],
      'exercise-physiology': ['physiotherapy', 'personal-training', 'group-fitness', 'pilates', 'yoga'],
      'group-fitness': ['exercise-physiology', 'personal-training', 'pilates', 'yoga'],
      'life-coaching': ['counselling', 'meditation', 'physiotherapy', 'psychology'],
      homeopathy: ['ayurveda', 'naturopathy', 'nutrition', 'reiki'],
      massage: ['acupuncture', 'chiropractic', 'physiotherapy', 'podiatry', 'reflexology', 'osteopathy'],
      meditation: ['counselling', 'reiki', 'psychology', 'life-coaching'],
      myotherapy: ['chiropractic', 'physiotherapy', 'massage'],
      naturopathy: ['ayurveda', 'nutrition', 'reiki', 'homeopathy'],
      nutrition: ['ayurveda', 'naturopathy', 'reiki', 'homeopathy'],
      osteopathy: ['acupuncture', 'chiropractic', 'massage', 'physiotherapy', 'podiatry', 'reflexology'],
      'personal-training': ['physiotherapy', 'exercise-physiology', 'group-fitness', 'pilates', 'yoga'],
      physiotherapy: ['acupuncture', 'chiropractic', 'massage', 'reflexology', 'podiatry', 'osteopathy'],
      pilates: ['exercise-physiology', 'group-fitness', 'personal-training', 'yoga'],
      podiatry: ['acupuncture', 'chiropractic', 'massage', 'physiotherapy', 'reflexology', 'osteopathy'],
      psychology: ['counselling', 'meditation', 'life-coaching'],
      reflexology: ['acupuncture', 'chiropractic', 'massage', 'physiotherapy', 'podiatry', 'osteopathy'],
      reiki: ['ayurveda', 'meditation', 'naturopathy', 'nutrition', 'homeopathy'],
      yoga: ['exercise-physiology', 'group-fitness', 'personal-training', 'pilates']
    };
    return relatedCategories;
  }

  public static get CATEGROY_GROUPS(): any {
    return {
      Physical: [
        {
          name: 'Acupuncture',
          slug: 'acupuncture'
        },
        {
          name: 'Chiropractic',
          slug: 'chiropractic'
        },
        {
          name: 'Exercise Physiology',
          slug: 'exercise-physiology'
        },
        {
          name: 'Massage',
          slug: 'massage'
        },
        {
          name: 'Myotherapy',
          slug: 'myotherapy'
        },
        {
          name: 'Nutrition',
          slug: 'nutrition'
        },
        {
          name: 'Osteopathy',
          slug: 'osteopathy'
        },
        {
          name: 'Physiotherapy',
          slug: 'physiotherapy'
        },
        {
          name: 'Pilates',
          slug: 'pilates'
        },
        {
          name: 'Podiatry',
          slug: 'podiatry'
        },
        {
          name: 'Reflexology',
          slug: 'reflexology'
        }
      ],
      Mental: [
        {
          name: 'Counselling',
          slug: 'counselling'
        },
        {
          name: 'Life Coaching',
          slug: 'life-coaching'
        },
        {
          name: 'Meditation',
          slug: 'meditation'
        },
        {
          name: 'Psychology',
          slug: 'psychology'
        },
        {
          name: 'Yoga',
          slug: 'yoga'
        }
      ],
      Natural: [
        {
          name: 'Ayurveda',
          slug: 'ayurveda'
        },
        {
          name: 'Chiropractic',
          slug: 'chiropractic'
        },
        {
          name: 'Homeopathy',
          slug: 'homeopathy'
        },
        {
          name: 'Naturopathy',
          slug: 'naturopathy'
        },
        {
          name: 'Osteopathy',
          slug: 'osteopathy'
        }
      ],
      Fitness: [
        {
          name: 'Exercise Physiology',
          slug: 'exercise-physiology'
        },
        {
          name: 'Group Fitness',
          slug: 'group-fitness'
        },
        {
          name: 'Personal Training',
          slug: 'personal-training'
        },
        {
          name: 'Pilates',
          slug: 'pilates'
        },
        {
          name: 'Yoga',
          slug: 'yoga'
        }
      ],
      Online: [
        {
          name: 'Ayurveda',
          slug: 'ayurveda'
        },
        {
          name: 'Counselling',
          slug: 'counselling'
        },
        {
          name: 'Dietetics',
          slug: 'dietetics'
        },
        {
          name: 'Group Fitness',
          slug: 'group-fitness'
        },
        {
          name: 'Homeopathy',
          slug: 'homeopathy'
        },
        {
          name: 'Hypnotherapy',
          slug: 'hypnotherapy'
        },
        {
          name: 'Life Coaching',
          slug: 'life-coaching'
        },
        {
          name: 'Meditation',
          slug: 'meditation'
        },
        {
          name: 'Naturopathy',
          slug: 'naturopathy'
        },
        {
          name: 'Nutrition',
          slug: 'nutrition'
        },
        {
          name: 'Personal Training',
          slug: 'personal-training'
        },
        {
          name: 'Pilates',
          slug: 'pilates'
        },
        {
          name: 'Psychology',
          slug: 'psychology'
        },
        {
          name: 'Reiki',
          slug: 'reiki'
        },
        {
          name: 'Yoga',
          slug: 'yoga'
        }
      ]
    };
  }

  public static get SUPER_CATEGORY_SERVICE(): any {
    const superCatServices = [
      'physiotherapy',
      'personal-training',
      'dietetics',
      'chiropractic',
      'myotherapy',
      'osteopathy',
      'reiki',
      'podiatry',
      'acupuncture',
      'nutrition',
      'pilates',
      'naturopathy',
      'massage',
      'psychology',
      'yoga',
      'ayurveda',
      'counselling',
      'exercise-physiology',
      'reflexology',
      'homeopathy',
      'life-coaching',
      'meditation',
      'group-fitness',
      'dance',
      'martial-arts',
      'gym',
      'diarrhoea',
      'dental-pain',
      'nausea',
      'osteoarthritis',
      'back-pain',
      'anxiety',
      'arthritis',
      'asthma',
      'headaches',
      'neck-pain',
      'menstrual-cramps',
      'vomiting',
      'depression',
      'fatigue',
      'gut-health',
      'high-blood-pressure',
      'hot-flashes',
      'diabetes',
      'infertility',
      'insomnia',
      'pms',
      'stress'
    ];
    return superCatServices;
  }

  public static get MAJOR_CITIES(): any {
    const majorCities = ['melbourne', 'brisbane', 'sydney', 'adelaide', 'perth'];
    return majorCities;
  }

  public static get STATE_ACRONYMS(): any {
    const stateAcronyms = ['NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'ACT'];
    return stateAcronyms;
  }

  public static get SHOULD_ENCRYPT(): boolean {
    return environment.login_encrypt;
  }

  public static get AUTH0_CONFIG() {
    return environment.auth0;
  }

  public static get LOGIN_TYPE(): string {
    return environment.login_type;
  }

  public static get WeeksNameList() {
    return [
      {
        week_caps: 'Monday',
        week_short_caps: 'Mon',
        week_number: 0
      },
      {
        week_caps: 'Tuesday',
        week_short_caps: 'Tue',
        week_number: 1
      },
      {
        week_caps: 'Wednesday',
        week_short_caps: 'Wed',
        week_number: 2
      },
      {
        week_caps: 'Thursday',
        week_short_caps: 'Thu',
        week_number: 3
      },
      {
        week_caps: 'Friday',
        week_short_caps: 'Fri',
        week_number: 4
      },
      {
        week_caps: 'Saturday',
        week_short_caps: 'Sat',
        week_number: 5
      },
      {
        week_caps: 'Sunday',
        week_short_caps: 'Sun',
        week_number: 6
      }
    ];
  }

  public static get earliestAvailabilityOptions() {
    return [
      { name: 'Today', value: 'today' },
      { name: 'Tomorrow', value: 'tomorrow' },
      { name: 'Day after tomorrow', value: 'day_after_tomorrow' }
    ];
  }

  public static get distanceOptions() {
    return [
      { name: 'Any distance', value: '20' },
      { name: 'Under 1km', value: '1' },
      { name: '5km', value: '5' },
      { name: '10km', value: '10' },
      { name: '25km', value: '25' }
    ];
  }

  public static get appointmentTypes() {
    return [
      { name: 'At Venue', value: 'in person' },
      { name: 'Online', value: 'online' },
      { name: 'Mobile', value: 'mobile' }
    ];
  }

  public static get WORKCOVER_CATEGORIES() {
    return [
      'Physiotherapy',
      'Exercise Physiology',
      'Psychology',
      'Counselling',
      'Osteopathy',
      'Chiropractic',
      'Dietetics',
      'Podiatry',
      'Occupational Therapy'
    ];
  }

  public static get QUESTIONNAIRE_PRACTITIONER() {
    return [
      {
        id: 1,
        name: 'categories',
        questionText: 'What is your area of expertise or specialization?',
        options: [
          'Physiotherapy',
          'Chiropractic Care',
          'Psychology',
          'Acupuncture',
          'Massage Therapy',
          'Nutrition',
          'Other (please specify)'
        ],
        allowMultiple: true
      },
      {
        id: 2,
        name: 'experience',
        questionText: 'How long have you been practicing?',
        options: ['Less than 1 year', '1-3 years', '3-5 years', '5-10 years', 'More than 10 years'],
        allowMultiple: false
      },
      {
        id: 3,
        name: 'treatment',
        questionText: 'What is your approach to treatment?',
        options: [
          'Holistic approach',
          'Evidence-based approach',
          'Client-focused care',
          'Preventative care',
          'Other (please specify)'
        ],
        allowMultiple: false
      },
      {
        id: 4,
        name: 'goals',
        questionText: 'What is your goal when working with clients?',
        options: [
          'Help clients achieve their health goals',
          'Provide long-term solutions',
          'Improve overall well-being',
          'Enhance recovery and rehabilitation',
          'Other (please specify)'
        ],
        allowMultiple: false
      }
    ];
  }
  public static get QUESTIONNAIRE_BUSINESS() {
    return [
      {
        id: 1,
        name: 'categories',
        questionText: 'What services does your business offer?',
        options: [
          'Physiotherapy',
          'Chiropractic Care',
          'Psychology',
          'Acupuncture',
          'Massage Therapy',
          'Corporate Wellness',
          'Other (please specify)'
        ],
        allowMultiple: true
      },
      {
        id: 2,
        name: 'mission',
        questionText: 'What is your business’s mission or goal?',
        options: [
          'Provide holistic wellness',
          'Make healthcare accessible to all',
          'Improve the quality of life for clients',
          'Focus on preventative health',
          'Other (please specify)'
        ],
        allowMultiple: false
      },
      {
        id: 3,
        name: 'selling_point',
        questionText: 'What makes your business unique?',
        options: [
          'Specialized team of practitioners',
          'Focus on long-term wellness',
          'Personalized treatment plans',
          'Evidence-based approach',
          'Other (please specify)'
        ],
        allowMultiple: false
      },
      {
        id: 4,
        name: 'experience',
        questionText: 'How does your business ensure a positive client experience?',
        options: [
          'Personalized treatment plans',
          'Ongoing client support',
          'Focus on client education',
          'Seamless booking and communication',
          'Other (please specify)'
        ],
        allowMultiple: false
      }
    ];
  }

  public static get FILTERSLIST() {
    return [
      {
        type: 'checkbox',
        function: 'earliest_availability',
        heading: 'Earliest availability',
        options: this.earliestAvailabilityOptions,
        value: []
      },
      {
        type: 'radio',
        function: 'distance_from_me',
        heading: 'Distance from me',
        options: this.distanceOptions,
        value: ''
      },
      {
        type: 'checkbox',
        function: 'appointment_type',
        heading: 'Appointment type',
        options: this.appointmentTypes,
        value: []
      },
      {
        type: 'checkbox',
        function: 'specialization',
        heading: 'Specialises in',
        options: [],
        value: []
      },
      {
        type: 'radio',
        function: 'gender',
        heading: 'Gender',
        options: [
          { name: 'Female', value: 'female' },
          { name: 'Male', value: 'male' },
          { name: 'Non-binary', value: 'non-binary' }
        ],
        value: ''
      },
      {
        type: 'checkbox',
        function: 'languages_spoken',
        heading: 'Languages spoken',
        options: [],
        value: []
      }
    ];
  }
}
